import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És un dels bolets més apreciats a la cuina compost per un capell de 4 a 10 cm, carnós, convex al principi per passar a deprimit i amb el marge enrotllat. El color del capell va del blanc crema al bru rogenc. Les làmines són decurrents, gruixudes, un poc separades, de color blanquinós o grogós. El peu, que pot ser central o excèntric, és massís, cilíndric, llarg i normalment corbat i atenuat a la base. Les espores són blanques en massa, el·líptiques, de 10-13 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      